:root {
    /* CSS HEX */
    --dark-green: #006472;
    --teal: #03A2A5;
    --yellow: #C4A11B;
    --dark-blue: #11365A;
    --gray: #A1ABB2;
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

.App {
    font-family: sans-serif;
}

html {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.home {
    height: inherit;
}

body {
    height: 100vh;
    width: 100vw;
}

.seperator {
    width: 100%;
    height: 100px;
}

.navigation .header_text {
    color: var(--dark-blue);
    padding-top: 3vh;
    text-align: center;
}

.navigation a {
    text-decoration: none;
    margin-left: 6.5%;
    margin-right: 6.5%;
    font-family: 'Oswald', sans-serif;
    color: var(--dark-blue);
}

.filler {
    height: 75vh;
}

.foot {
    margin-top: 90vh;
    position: fixed;
    width: 110vw;
    height: 10vh;
}

#gradient_1 {
    background-image: linear-gradient(to right, var(--dark-green), var(--yellow));
    height: 2vh;
}

#bottom_blue {
    height: 8vh;
    background: var(--dark-blue);
}

#gradient_2 {
    opacity: 0.85;
    position: absolute;
    background: radial-gradient(at top left, var(--dark-green), white);
    height: 90vh;
    width: 100vw;
}

#backdrop {
    transform: rotate(180deg);
    mix-blend-mode: multiply;
    position: absolute;
    height: 90vh;
    width: 100vw;
    min-width: 1920px;
}

#logowhite {
    position: fixed;
    margin-top: 66vh;
    margin-left: 4vw;
    height: 20vh;
    width: auto;
}

.card-primary {
    position: absolute;
    max-width: 50vw;
    height: 50vh;
}

.navigation {
    position: absolute;
}

.total {
    height: inherit;
}

.main-button {
    position: absolute;
    font-family: 'Source Sans Pro', sans-serif;
    background: var(--dark-blue);
    color: white;
    width: 25%;
    height: 12%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-button {
    margin-left: 7.5%;
}

.right-button {
    margin-left: 42%;
}

.split-button-1,.split-button-2 {
    height: 12%;
    font-size: 14px;
}

.split-button-1 {
    width: 25%;
    right: 8.5%;
    bottom: calc(5% + 12% + 30px);
}

.split-button-2 {
    width: 25%;
    right: 8.5%;
    bottom: 5%;
}

.split-button-2 {
    margin-top: 3vh;
    width: 40%;
    right: 8.5%;
}

.center-button {
    margin-left: 37.5%;
}

.quiz-2-button {
    height: 10%;
    margin-left: 25%;
    width: 50%;
}

.main-button:hover {
    background: var(--teal);
    color: white;
}

.main_text_view {
    height: 50vh;
}

.main_text_view h3 {
    text-align: center;
    padding-top: 3%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2.5vh;
}

h4 {
    font-size: 2vh;
    text-align: center;
}

.upper-text {
    height: 30%;
}

.quiz-2-text h3 {
    text-align: center;
    padding-top: 3%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2.5vh;
}

input {
    position: absolute;
}

.error {
    border-color: red;
    outline-color: red;
}

#head {
    position: absolute;
    margin-left: 15vw;
    width: 6vw;
    height: auto;
}

#polygon-2 {
    position: absolute;
    margin-left: 24.2vw;
    margin-top: 3vh;
    width: 2.5%;
}

.button-bar {
    margin-bottom: 5%;
    height: 12%;
}

#left-arrow {
    margin-right: 17%;
    height: auto;
    width: 17%;
}

#right-arrow {
    margin-left: 17%;
    height: auto;
    width: 17%;
}

#first_name {
    position: absolute;
    width: 35%;
    margin-left: 30%;
}

#skill-dropdown {
    height: 15%;
    width: 40%;
}

.pathways {
    margin-top: 10vh;
    margin-left: 20px;
    height: 80vh;
    min-height: 1000px;
    position: absolute;
    width: 100%;
    display: flex;
}

.filter {
    width: 100%;
    margin: 0;
    background: #EBE6E6;
    height: 88%;
    overflow-y: auto;
    padding: 0;
}

/*SDB*/
.side-bar {
    height: 80vh;
    margin-left: 2.5vw;
    float: right;
    width: max(30vw, 300px);
    max-width: 500px;
    overflow-y: auto;
}

/*PTH*/
.pathways-section {
    padding: 0;
    float: left;
    width: min(66vw, 100vw - 300px);
    height: 100%;
    background: white;
}

#filter-category {
    /*height: 12.5%;*/
    width: 98%;
    margin-left: 4px;
    padding: 0;
    display: flex;
    flex-flow: row;
    overflow-y: hidden;
}

#filter-timeframe {
    /*height: 12.5%;*/
    width: 98%;
    margin-left: 4px;
    padding: 0;
    display: flex;
    flex-flow: row;
    overflow-y: hidden;
}

#filter-skills {
    /*height: 12.5%;*/
    width: 98%;
    margin-left: 4px;
    padding: 0;
    display: flex;
    flex-flow: row;
    overflow-y: hidden;
}

#filter-salary {
    height: 12.5%;
    display: flex;
    flex-flow: row;
    overflow-y: hidden;
}

.filter-button {
    display: inline-block;
    font-size: max(1.5vh, 12px);
}

.filter-button:hover {
    color: white;
    background: var(--teal);
    border-color: var(--teal);
}

.btn-primary {
    border-radius: 20px;
    background: var(--gray);
    border: solid rgba(0, 0, 0, 0);
    margin: 1%;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
    outline: 0;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
    opacity: 0.75;
}

/* Hover must come before focus */
.btn-primary:hover {
    border: solid rgba(0, 0, 0, 1);
    opacity: 1;
}

.btn-primary:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
    outline: 0;
    background: var(--gray);
    border: solid rgba(0, 0, 0, 0);
    opacity: 0.75;
}


/*.btn-primary:active {*/
/*    -webkit-box-shadow: 0 0 0 30px white inset !important;*/
/*    box-shadow: none;*/
/*    outline: 0;*/
/*    background: #03A2A5;*/
/*    border: 0;*/
/*}*/

.selected-filter {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
    outline: 0;
    background: #03A2A5;
    border: solid rgba(0, 0, 0, 1);
    opacity: 1;
}

/* Hover must come before focus */
.selected-filter:hover {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
    outline: 0;
    background: #03A2A5;
    border: solid rgba(0, 0, 0, 0);
    opacity: 0.75;
}

.selected-filter:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
    outline: 0;
    background: #03A2A5;
    border: solid rgba(0, 0, 0, 1);
    opacity: 1;
}

.filter-top-panel {
    height: 12%;
    width: 100%;
    min-width: 340px;
    display: flex;
    flex-flow: row;
    background: #EBE6E6;
    margin: 0;
}

.btn-secondary {
    border-radius: 20px;
    height: 80%;
    width: 40%;
    margin-top: 5px;
    background: var(--dark-blue);
    border-color: var(--dark-blue);
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    font-size: 1.9vh;
}

.btn-secondary:hover {
    color: white;
    background: var(--teal);
    border-color: var(--teal);
}

#apply_button {
    font-size: 1.7vh;
}

.filter-top-panel h5 {
    width: 20%;
    margin: 1%;
}

.filter-top-panel img {
    margin-top: 3%;
    width: 20%;
    height: 40%;
}

#filter-salary input {
    color: var(--dark-blue);
    width: 10%;
    font-size: 1.9vh;
}

.round-input {
    border-radius: 15px;
}

.filter h5 {
    font-family: 'Poppins', sans-serif;
}

/* Popup style */
.popup-box {
    z-index: 1050;
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.flip-card {
    background-color: transparent;
    perspective: 1000px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
}

.flip-card-front, .flip-card-back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front-inner {
    overflow-y: auto;
    height: 96%;
}

.flip-card-back {
    transform: rotateY(180deg);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
}

.pltly-graph {
    width: 82%;
    height: 100%;
}

.calc-input-row {
    position: fixed;
    padding: 0;
    left: calc(82% + 10px);
    top: 15px;
    width: 18%;
    height: calc(100% - 30px);
}

.input-row {
    width: 100%;
    padding: 0;
    height: 60px;
    margin: 0;
}

.year-btn-group {
    width: 100%;
    padding: 0;
}

.year-btn {
    font-size: 12px;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.graph-button {
    width: 70%;
    height: 70%;
    margin-top: 10px;
    margin-left: 15%;
    padding: 0;
}

.box-overlay {
    width: 80%;
    max-width: 1000px;
    min-width: 656px;
    height: 60%;
    min-height: 409px;
    margin-left: max(10%, 50vw - 500px);
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    padding: 20px;
    overflow: auto;
    overflow-y: hidden;
}

.btn-close {
    padding: 0;
    position: fixed;
    top: calc(15vh + 28px);
}

.btn-close {
    padding: 0;
    position: fixed;
    top: calc(15vh + 28px);
}

.btn-close {
    padding: 0;
    position: fixed;
    top: calc(15vh + 28px);
}

.close-icon {
    content: 'X';
    cursor: pointer;
    background: #ededed;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.popup-fields {
    white-space: nowrap;
}

.popup-body {
    height: 100%;
    background: #e6f6f6;
    /*padding-bottom: 5px;*/
    margin-bottom: 5px;
}

.popup-body p {
    font-size: max(11px, 1.7vh);
    margin-bottom: 8px;
}

.popup-body b {
    font-size: max(11px, 1.7vh);
}

.popup-description-text {
    height: 30%;
    margin-bottom: 0;
}

.popup-text {
    height: 17%;
    margin-bottom: 0;
}

.popup-header {
    height: 10%;
    margin-bottom: 2%;
}

.btn-info {
    width: 100%;
    max-width: 180px;
    height: 100%;
    background: #11365A;
    color: white;
    border-radius: 10px;
    border-color: black;
}

.btn-info:hover {
    background: #03A2A5;
    color: white;
    border-color: var(--teal);
}

.button-close {
    width: 5%;
    height: auto;
    color: gray;
    background: white;
    border-color: white;
}

.journey-header {
    height: 10%;
    width: 100%;
    padding: 0;
    margin: 0;
}

#journey-user-name {
    text-align: center;
    font-size: 1.9vh;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    margin-top: 5px;
    width: 70%;
}

.delete-journey-button {
    width: 30%;
    height: 100%;
    font-size: 1.4vh;
    padding: 0;
    border-radius: 30px;
    background: rgb(230, 25, 75);
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
    outline: 0;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}

.delete-journey-button:hover {
    background: rgb(230, 25, 75);
    opacity: 0.75;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
    outline: 0;
}

.delete-journey-button:focus {
    background: rgb(230, 25, 75);
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: none;
}

.journey-container {
    width: 100%;
    height: 60%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2%;
}

.react-flow {
    z-index: 0;
}

.card-secondary {
    margin-bottom: 2%;
}

.black-card-outline {
    border: 2px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.journey-item {
    font-size: 85%;
    padding: 4%;
    align-items: center;
}

.journey-item-top {
    width: 100%;
}

.journey-item-bottom {
    width: 100%;
}

.btn-success {
    text-align: center;
    border-radius: 20px;
    max-width: 180px;
}

.btn-success:hover {
    cursor: initial;
}

.bottom-button-bar {
    height: 100%;
    width: 100%;
}

.pathways-button-bar {
    padding: 0;
    width: 100%;
    height: 100%;
}

.open-carousel-btn {
    background: #11365A;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
    width: 48%;
    font-size: 1.7vh;
    float: left;
    height: min(40px, 100%);
}

.open-carousel-btn:hover {
    background: var(--teal);
    color: white;
}

.carousel-custom {
    padding: 3%;
    width: 40%;
    min-width: 400px;
    height: 60%;
    top: 20%;
    left: 30%;
    border: 2px solid #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.carousel-title {
    font-family: 'Oswald', sans-serif font-size;
}

.carousel-content {
    height: 94%;
    background-color: #e6f6f6;
}

/*.carousel-background {*/
/*    position: fixed;*/
/*    margin-top: 3.5%;*/
/*    height: 45vh;*/
/*    width: 54vw;*/
/*    background-color: #03A2A5;*/
/*    opacity: 0.1;*/
/*}*/

.carousel-content-upper {
    height: 33%;
    opacity: initial;
}

.carousel-content-upper img {
    margin-top: 4%;
    height: 50%;
    width: auto;
}

.carousel-close-button {
    z-index: 9999;
}

.example-journey-person {
    height: 100%;
    margin-left: 20%;
}

.col-9 {
    padding-left: max(90px, 10%);
}

/*.example-journey-person {*/
/*    height: 80%;*/
/*    margin-left: 20%;*/
/*}*/

/*.col-9 {*/
/*    padding-left: max(90px, 10%);*/
/*}*/

.carousel-content-description {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 2vh;
    height: 40%;
    color: black;
}

.carousel-content-bottom {
    width: 100%;
    height: 25%;
}

.left-arrow {
    float: left;
    width: 10%;
    background: transparent;
    border: 0;
    height: 100%;
}

.left-arrow:hover {
    filter: invert(100%);
}

.right-arrow {
    float: right;
    width: 10%;
    background: transparent;
    border: 0;
    height: 100%;
}

.right-arrow:hover {
    filter: invert(100%);
}

.carousel-close-button {
    position: fixed;
    top: 23%;
    left: max(26% + 400px - 5px, 67%);
    color: black;
    background: transparent;
    border: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 3vh;
}

.printout-close-button {
    z-index: 1051;
    position: fixed;
    top: 6%;
    left: calc(50% + 370px);
    color: black;
    background: transparent;
    border: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 3vh;
}

.carousel-close-button:hover {
    color: gray;
}

.printout-close-button:hover {
    color: gray;
}

.color-box {
    width: 15px;
    height: 15px;
    display: inline-block;
    background-color: #ccc;
    left: 5px;
    top: 5px;
    margin-right: 5px;
}

.printout {
    width: 816px;
    margin-left: calc(50vw - 408px);
    margin-top: 40px;
    overflow-y: auto;
}

.printout-page {
    height: 1054px;
}

.printout-header {
    height: 70px;
}

.printout-header-text {
    font-size: 25px;
    margin-left: 200px;
    margin-top: 30px;
    color: #11365A;
}

.printout-content {
    margin-left: calc((814px - (0.93 * 814px)) / 2);
    margin-top: 20px;
    width: 93%;
    background: #e6f6f6;
    height: 88%;
}

.printout-result-card {
    background: #C4C4C4AD;
    min-height: 100%;
    width: 200px;
    border: #C4C4C4AD;
}

.printout-result-text-title {
    margin-left: 80px;
    margin-top: 20px;
}

.printout-button:hover {
    cursor: default;
}

.download-button {
    margin-top: 20px;
    margin-left: 258px;
    background: var(--dark-blue);
    color: white;
}

.download-button:hover {
    background: var(--teal);
    color: white;
}

.filter-bubble {
    z-index: 1002;
    position: fixed;
    top: 12%;
    left: calc(min(66vw, 100vw - 300px) - max(7vw, 80px));
    height: max(10%, 80px);
    width: auto;
}

.popup-left {
    z-index: 1003;
    position: fixed;
    top: 12%;
    left: 5%;
    height: max(10%, 80px);
    width: auto;
}

.lower-logo {
    position: fixed;
    bottom: 16%;
    left: calc(2% + 20px);
    height: max(20%, 150px);
    width: auto;
}

.compare-container {
    position: absolute;
    width: 100%;
    top: 100px;
    height: calc(90vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}

.compare-back-button {
    position: fixed;
}

.comparison-monte-carlo {
    width: 73vw;
    height: 600px;
    margin-left: 18.5vw;
    margin-top: 80px;
    margin-bottom: 80px;
}

.comparison-dropdown-row {
    margin-top: 80px;
    height: 80px;
    width: calc(90% - 80px);
    margin-left: calc((100vw - calc(90% - 80px))/2);
    padding: 0;
}

.dropdown-label-text {
    color: #11365A;
    font-family: 'Source Sans Pro', sans-serif;
}

.comparison-table {
    height: 600px;
    width: 70vw;
    margin-left: 15vw;
    background: whitesmoke;
}

.comparison-table-row {
    padding: 0;
    margin: 0;
}

.compare-error-row {
    height: 20px;
    color: #c91010;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 40px;
    margin-left: calc((100vw - calc(90% - 110px))/2);
}

.pName-text {
    font-family: 'Source Sans Pro', sans-serif;
    color: #11365A;
    border: solid black 1px;
}

.pTitle-text {
    font-family: 'Source Sans Pro', sans-serif;
    border: solid black 1px;
}

.p1-text,.p2-text {
    font-family: 'Source Sans Pro', sans-serif;
    color: #006472;
    border: solid black 1px;
}

.select-btn {
    width: 70%;
    margin-left: 30%;
    height: 50px;
    margin-top: 15px;
}

.compare-nav-left,.compare-nav-right {
    position: fixed;
    z-index: 1060;
    height: 100px;
    width: 15vw;
    top: calc(50vh - 100px);
}

.compare-nav-left {
    left: 0;
}

.compare-nav-right {
    right: 0;
}

#edu-dropdown {
    width: calc(100% - 40px);
}

.compare-car-svg {
    width: 40px;
    height: 40px;
    background: white;
    padding: 0;
    border: solid transparent 2px;
    border-radius: 8px;
}

.compare-car-svg:hover {
    background: #adadad;
    cursor: pointer;
    border: solid white 2px;
}

.compare-car-svg img{
    width: 100%;
    height: 100%;
}

.add-as-pos-btn {
    height: 80%;
    width: 100%;
    font-size: 14px;
}

.invisible {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

.carousel-position-selected {
    background: rgb(92,99,106);
}

#map {
    height: 400px;
    width: 400px;
    margin: 0 calc((100vw - 400px)/2) 0 calc((100vw - 400px)/2);
}

.location-button {
    color: blue;
    font-size: 30px;
    height: 50px;
    width: 50px;
}

.resource-page-container {
    position: absolute;
    width: 100%;
    top: 100px;
    height: calc(90vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Source Sans Pro', sans-serif;
}

.resource-page-info {
    margin: 0 calc((100vw - min(80vw, 658px))/2) 0 calc((100vw - min(80vw, 658px))/2);
    background: whitesmoke;
    border: solid whitesmoke 3px;
    border-radius: 10px;
    width: min(80vw, 658px);
}

.resource-input-row {
    padding: 0;
    margin: 30px calc((100vw - min(70vw, 579px))/2) 30px calc((100vw - min(70vw, 579px))/2);
    
    width: min(70vw, 500px);
}

.resource-input {
    position: relative;
    width: 300px;
    margin: 0;
    height: 50px;
}

.resource-search-btn {
    position: relative;
    width: 150px;
    margin: 0;
    height: 100%;
}

.resource-btween {
    width: 50px;
}

.resource-target-btn {
    padding: 0;
    position: relative;
    width: 50px;
    height: 50px;
    float: right;
    font-size: 30px;
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

.target-icon {
    width: 50px;
    height: 50px;
}

.no-padding{
    padding: 0;
}

.map-container {
    width: 400px;
    height: 400px;
}

#video-resources {
    margin: 60px 30px 0 30px;
}

#video-resources a{
    font-size: 15px;
}

#video-resources h3 {
    font-size: 20px;
}

.resource-link {
    background: whitesmoke;
    margin-bottom: 10px;
    border: solid whitesmoke 3px;
    border-radius: 15px;
    text-align: center;
    width: 700px;
    margin-left: max(0px, calc((100vw - 700px)/2));
}

.location-character {
    font-size: 30px;
    display: inline-block;
    vertical-align: bottom;
    margin-top: -15px;
    margin-bottom: -7px;
}

.shipyard-popup-row {
    margin-bottom: 6px;
}

.shipyard-popup-key {
    font-size: 15px;
}

.shipyard-popup-val {
    font-size: 12px;
}

.shipyard-maritime-school {
    display: block;
    font-style: italic;
}
